export const SET_USER = "SET_USER";
export const CASE_SUCCESS = "CASE_SUCCESS";
export const GET_NEUTRAL_VACATION = "GET_NEUTRAL_VACATION";
export const SAVE_NEUTRAL_VACATION = "SAVE_NEUTRAL_VACATION";
export const DELETE_NEUTRAL_VACATION = "DELETE_NEUTRAL_VACATION";

export const NeutralVacationSource = Object.freeze({
  CMS2: 0,
  NP: 1,
});

export const NeutralVacationStatus = Object.freeze({
  APPROVED: 0,
  PENDINGFORAPPROVAL: 1,
  REJECTED: 2,
});
