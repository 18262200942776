import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "./calendar.scss";
import {
  //// faWindowClose,
  faClock,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Overlay, Tooltip } from "react-bootstrap";
import {
  HEARINGS_BY_NEUTRAL,
  HEARINGS,CASE_SYNC,
  ALLHEARING_OFFICERS,REALTIME_DATA
} from "../../api/baseURL";
import axios from "axios";
import * as Utility from "../../utils/utility";
import RBCToolbar from "./RBCToolBar";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
import LoadingOverlay from "react-loading-overlay";
import { decryptItem, encryptItem } from "../../shared/Encrypt";
import { Button } from "@mui/material";
const selectAll = "Select All";
const height = "100% !important";
const options = [
  {
    key: selectAll,
    value: selectAll,
    name: selectAll,
  },
];
const API_GET_DATE_FORMAT = "YYYY-MM-DD";
const localizer = momentLocalizer(moment);
const IconStyle = {
  cursor: "pointer",
};
const isZoomLinkEnabled = (date) => {
  const advancedhearingDate = new Date(date);
  advancedhearingDate.setHours(advancedhearingDate.getHours() - 1);
  const tomorrow = new Date(advancedhearingDate);
  tomorrow.setDate(advancedhearingDate.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  const currentdatetime = new Date();
  return currentdatetime >= advancedhearingDate && currentdatetime <= tomorrow
      ? true
      : false;
};

const getcasecolorcode = (casetype) => {
  return (
    <>
      <div
        style={{ marginRight: "5px" }}
        className={
          casetype.trim().toLowerCase().includes("mediation")
            ? "circleBase hold"
            : "circleBase inprogress"
        }
      ></div>
    </>
  );
};

const TooltipContent = ({ onClose, event }) => {
  const obj = event.event;
  return (
    <React.Fragment>
      <FontAwesomeIcon
        // icon={faWindowClose}
        className="pull-right"
        style={IconStyle}
        onClick={onClose}
      />
      <div>{obj["caseName"]}</div>
      <div>
        <div style={{ color: "#888888" }}>
          <FontAwesomeIcon icon={faCalendarDay} />
        </div>
        <div style={{ color: "#000" }}>
          {Utility.getdate(obj["hearingStartTime"])}
        </div>
      </div>
      <div>
        <div style={{ color: "#888888" }}>
          <FontAwesomeIcon icon={faClock} />
        </div>
        {obj["hearingStartTime"]}
        <div style={{ color: "#000" }}>
          {obj["hearingStartTime"]
            ? `${
              Utility.ConvertDate(
                obj["hearingStartTime"]
              )}-${Utility.ConvertDate(obj["hearingEndTime"])}`
            : null}
        </div>
      </div>

      <div style={{ color: "#000" }}>{event.event.hearingType}</div>
      {obj["hearingType"] === "VIDEO-CONF" && (
        <div className="row">
          <div className="container col-12">
            <a disbled
              href={isZoomLinkEnabled(obj["hearingStartTime"])?"#":obj["meetingURL"]}
              target="_blank" rel="noopener noreferrer"
              // className="btn btn-primary btn-medium"
              className={
                Utility.isZoomLinkEnabled(obj["hearingStartTime"])
                     ? " btn btn-primary btn-medium "
                     : "disabled-link btn btn-primary btn-medium"
               }
              role="button"
              aria-disabled="true"
            >
              Join Zoom Meeting
            </a>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

function Event(event) {
  const [showTooltip, setShowTooltip] = useState(false);
  const closeTooltip = () => {
    setShowTooltip(false);
  };
  const openTooltip = () => {
    setShowTooltip(true);
  };
  const ref = useRef(null);
  const getTarget = () => {
    return ReactDOM.findDOMNode(ref.current);
  };
  return (
    <div
      className="viewDoiv"
      style={{ display: "flex", alignItems: "center", height: "100%" }}
      ref={ref}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: height,
        }}
        onMouseOver={openTooltip}
        onBlur={closeTooltip}
        onMouseLeave={closeTooltip}
      >
        {event.view === "month" ? (
          <div
            className="monthView"
            style={{
              display: "flex",
              alignItems: "center",
              height: height,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {getcasecolorcode(event.event.event.caseType)}
              <div
                style={{
                  fontSize: "85%",
                  color: "#145187",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "140px",
                }}
              >
                {event.event.title}
              </div>
            </div>
          </div>
        ) : event.view === "week" ? (
          <div
            className="monthView"
            style={{
              display: "flex",
              alignItems: "center",
              height: height,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {getcasecolorcode(event.event.event.caseType)}
              <div style={{ fontSize: "55%", color: "#145187" }}>
                {event.event.title}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="dayView"
            style={{
              display: "block",
              flexDirection: "column",
              alignItems: "center",
              height: height,
              marginLeft: "0.5rem",
              marginTop: "10px",
              marginBottom: "10px", width:"100%"
            }}
          >
            <div style={{ fontSize: "65%", color: "#145187", float:"left", marginLeft:".3rem" }}>
              {getcasecolorcode(event.event.event.caseType)}
              {event.event.title}
            </div>
            <div style={{ fontSize: "65%", color: "#145187" , float:"left", margin:".1rem 0 0 .3rem" }}>
              {event.event.event.caseReference}
              &nbsp;&nbsp;{event.event.event.caseType}
            </div>
            <div style={{ fontSize: "65%", color: "#145187", float:"left", margin:".1rem 0 0 .3rem" }}>
              {event.event.event.hearingType}
              &nbsp;&nbsp;{event.event.event.hearingLocation}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "-.5rem 0 0 0.5rem",
              }}
            >
              {event.event.event.hearingType === "VIDEO-CONF" && (
                <div className="btn-calendar-meeting">
                  <div style={{ padding: "5px" }}>
                    <a
                      href={event.event.event.meetingURL}
                      target="_blank" rel="noopener noreferrer"
                      // className=""
                      className={
                        Utility.isZoomLinkEnabled(event.event.event.hearingDate)
                             ? ""
                             : "disabled-link"
                       } 
                      role="button"
                      aria-disabled="true"
                    >
                      Join Zoom Meeting
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Overlay
        rootClose
        target={getTarget}
        show={showTooltip}
        placement={event.view === "day" ? "auto" : "right-start"}
        onHide={closeTooltip}
        style={{ margin: 0 }}
      >
        <Tooltip id="test" onBlur={closeTooltip} onMouseLeave={closeTooltip}>
          <TooltipContent event={event.event} onClose={closeTooltip} />
        </Tooltip>
      </Overlay>
    </div>
  );
}

class CaseCalendar extends React.Component {
  constructor() {
    super();
    this.state = {
      isActive: false,
      events: [],
      startDate: "",
      endDate: "",
      hearingOfficers: [],
      view: "month",
      selectedneutralName: selectAll,
      selectedneutralEmail: "",
      selectedneutralId: 0,
      isLoading: false,
      daysdiff: 0,
      officerList: [],
      isclaendarLoaded: false,
    };
  }

  GetAllHearingOfficers = async (officerCode) => {
    this.setState({ isLoading: true });
    axios
      .get(ALLHEARING_OFFICERS, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        this.setState({ hearingOfficers: res.data, officerList: res.data });
        this.setState({ isLoading: false });
      });
  };
  GetDateDiff = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (endDate - startDate) / millisecondsPerDay;
  };
  GetNeutralCalendarINfo = async () => {
    const start = moment(new Date().setHours(0, 0, 0, 0))
      .startOf("month")
      .subtract(7, "days")
      .format(API_GET_DATE_FORMAT);
    const end = moment(new Date().setHours(23, 59, 59, 999))
      .endOf("month")
      .add(7, "days")
      .format(API_GET_DATE_FORMAT);

    this.setState({
      startDate: start,
      endDate: end,
      daysdiff: this.GetDateDiff(start, end),
    });
    const neutralEmail = Utility.IsHearingOfficer()
      ? decryptItem("neutralsEmail")
      : "";
    this.GetCalendarData(neutralEmail, start, end, true);
  };
  async componentDidMount() {
    if (!Utility.IsHearingOfficer()) {
      this.GetAllHearingOfficers();
    }
    this.GetNeutralCalendarINfo();
  }
  checkDateDiff = (view, neutralEmail, neutralId) => {
    let falg = 0;
    const date = new Date(this.state.startDate);
    let start = moment(date),
      end = moment(date);

    if (view === "month" && this.state.daysdiff < 30) {
      start = moment(date.setHours(0, 0, 0, 0))
        .startOf("month")
        .subtract(7, "days");
      end = moment(date.setHours(23, 59, 59, 999))
        .endOf("month")
        .add(7, "days");
      falg = 1;
    } else if (view === "week" && this.state.daysdiff < 7) {
      start = moment(date).startOf("isoWeek").subtract(1, "days");
      end = moment(date).endOf("isoWeek").subtract(1, "days");
      falg = 1;
    }
    if (falg === 1) {
      this.setState({
        startDate: start.format(API_GET_DATE_FORMAT),
        endDate: end.format(API_GET_DATE_FORMAT),
        daysdiff: this.GetDateDiff(start, end),
      });

      this.GetCalendarData(
        neutralEmail,
        start.format(API_GET_DATE_FORMAT),
        end.format(API_GET_DATE_FORMAT),
        false,
        neutralId
      );
    }
    return falg;
  };
  handleView = (event, target) => {
    

    const neutralEmail = Utility.IsHearingOfficer()
      ? decryptItem("neutralsEmail")
      : this.state.selectedneutralEmail;
    const neutralId = Utility.IsHearingOfficer()
      ? decryptItem ("jCode")
      : this.state.selectedneutralId;
    this.setState({ ...this.state, view: event });
    if (!this.checkDateDiff(event, neutralEmail, neutralId));
    {
      this.GetCalendarData(
        neutralEmail,
        this.state.startDate,
        this.state.endDate,
        false,
        neutralId
      );
    }
  };
  GetCalendarData = (neutralEmail, start, end, flag, neutralId) => {
    
    this.setState({ isLoading: true });
    let jCode = Utility.IsHearingOfficer()
      ? decryptItem ("jCode")
      : neutralId;

    const param =
      neutralEmail !== undefined && neutralEmail !== null && neutralEmail !== ""
        ? `?neutralEmail= ${neutralEmail}&userCode=${jCode}&startDate=${start}&endDate=${end}`
        : `?startDate=${start}&endDate=${end}`;
    const url = `${
      neutralEmail !== undefined && neutralEmail !== null && neutralEmail !== ""
        ? HEARINGS_BY_NEUTRAL
        : HEARINGS
    }${param}`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {

          if (res?.data && Object.keys(res?.data).length > 0) {
            let localDataBasedOnTimeZone=[]
            res.data.forEach(element => {
                element.hearingStartTime = 
                neutralEmail?
                moment((element.hearingStartTimeUTCFromCMS && ( element.hearingStartTimeUTCFromCMS.indexOf("GMT")?  
                Utility.convertUTCDateToUserTimeZone(element.hearingStartTimeUTCFromCMS) : 
                Utility.convertUTCDateToUserTimeZone(new Date(element.hearingStartTimeUTCFromCMS).toUTCString())
               ))).format("MM/DD/YYYY hh:mm:ss A") :

                moment((element.hearingStartTimeUTC && ( element.hearingStartTimeUTC.indexOf("GMT")?  
                Utility.convertUTCDateToUserTimeZone(element.hearingStartTimeUTC) : 
                Utility.convertUTCDateToUserTimeZone(new Date(element.hearingStartTimeUTC).toUTCString())
               ))).format("MM/DD/YYYY hh:mm:ss A");
                
               
                element.hearingEndTime = 
                neutralEmail?
                moment((element.hearingEndTimeUTCFromCMS && ( element.hearingEndTimeUTCFromCMS.indexOf("GMT")?  
                     Utility.convertUTCDateToUserTimeZone(element.hearingEndTimeUTCFromCMS) : 
                     Utility.convertUTCDateToUserTimeZone(new Date(element.hearingEndTimeUTCFromCMS).toUTCString())))).format("MM/DD/YYYY hh:mm:ss A")
                :
                    moment((element.hearingEndTimeUTC && ( element.hearingEndTimeUTC.indexOf("GMT")?  
                     Utility.convertUTCDateToUserTimeZone(element.hearingEndTimeUTC) : 
                     Utility.convertUTCDateToUserTimeZone(new Date(element.hearingEndTimeUTC).toUTCString())))).format("MM/DD/YYYY hh:mm:ss A");
               
                     localDataBasedOnTimeZone.push(element);
            });
            res.data=localDataBasedOnTimeZone;
        }

          res.data.forEach((obj) => {
            const caseName = obj["caseName"];
            obj["casetext"] =
              caseName.length > 30
                ? `${caseName.substring(0, 30)}...`
                : caseName;
            obj["title"] = obj["casetext"];
            obj["start"] = new Date(obj["hearingStartTime"]);
            obj["end"] = new Date(obj["hearingEndTime"]);
          });
          this.setState({ events: res.data, isActive: true });
          // // if (flag && !Utility.IsHearingOfficer()) {
          // //   let listOfficers = this.state.officerList;
          // //   if (neutralEmail === selectAll) {
          // //     listOfficers = this.state.officerList;
          // //   } else {
          // //     const officerCode = res.data.map((a) => a.hearingOfficerCode);
          // //     if (listOfficers !== []) {
          // //       listOfficers = listOfficers.filter((a) =>
          // //         officerCode.some((b) => b === a.hearingOfficerCode)
          // //       );
          // //     } else {
          // //       listOfficers = officerCode;
          // //     }

          // //   }
          // //   ////console.log("listOfficers.length", listOfficers.length);
          // //   ////this.setState({ hearingOfficers: listOfficers });
          // // }
        } else {
          this.setState({ events: [] });
        }
        this.setState({ isLoading: false, isclaendarLoaded: true });
      });
  };
  onNavigate = (date, view, action) => {
    this.setState({ isActive: true });
    let start = moment(date),
      end = moment(date);
    if (view === "day") {
      start = moment(date).startOf("day");
      end = moment(date).endOf("day").add(1, "days");
    } else if (view === "week") {
      start = moment(date).startOf("isoWeek").subtract(1, "days");
      end = moment(date).endOf("isoWeek").subtract(1, "days");
    } else if (view === "month") {
      start = moment(date.setHours(0, 0, 0, 0))
        .startOf("month")
        .subtract(7, "days");
      end = moment(date.setHours(23, 59, 59, 999))
        .endOf("month")
        .add(7, "days");
    }
    this.setState({
      startDate: start.format(API_GET_DATE_FORMAT),
      endDate: end.format(API_GET_DATE_FORMAT),
      daysdiff: this.GetDateDiff(start, end),
    });
    ////console.log(this.state.daysdiff);
    const neutralEmail = Utility.IsHearingOfficer()
      ? decryptItem("neutralsEmail")
      : this.state.selectedneutralEmail;
      
    const neutralId = Utility.IsHearingOfficer()
      ? decryptItem ("jCode")
      : this.state.selectedneutralId;
    this.GetCalendarData(
      neutralEmail,
      start.format(API_GET_DATE_FORMAT),
      end.format(API_GET_DATE_FORMAT),
      false,
      neutralId
    );
  };
  handleSelectEvent = (event, target) => {
    this.setState({ isLoading: true });
    ////const hearingCode = event["hearingCode"];
    encryptItem("hearingDetails",JSON.stringify({hearingCode: event["hearingCode"]
    , caseType: event["caseType"],
      goBack:'Calendar', pendingAt:'schedulingInformation'}));    
    //const url = `/caseDetails/${hearingCode}/${event["caseType"]}/Calendar/schedulingInformation`;
    this.caseSync(event["caseCode"]);
  };

 caseSync=(caseCode)=>{
  const PMASyncbody = {
    caseCode: caseCode,
  };

  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  if(REALTIME_DATA)
  { let flag = false;
    axios
      .post(CASE_SYNC, PMASyncbody, config)
      .then((res2) => {
        if (res2.status === 200) {
          const url = `/caseDetails`;
          this.props.history.push(url);
            flag = true;
              console.log("case data synced")
            
  }
  }); 
  setTimeout(function(){
    // In the timer function that gets executed after 5 seconds, check // the flag value. If we got the response already it would have been // set to true. Otherwise false
      if (!flag)
      {
      const url = `/caseDetails`;
      this.props.history.push(url);
    }
    }, 15000); 
  }
  else
  {
    const url = `/caseDetails`;
        this.props.history.push(url);
  }  
}

  hearingOfficersrender = () => {
    const { hearingOfficers, selectedneutralName } = this.state;
    if (options.length === 1) {
      hearingOfficers
        .filter(
          (u) => u.hearingOfficerFName !== null && u.hearingOfficerLName !== null
        )
        .forEach((item) => {
          const obj = {};
          obj["key"] = item.hearingOfficerEmail;
          obj["value"] = item.hearingOfficerCode;
          obj[
            "name"
          ] = `${item.hearingOfficerLName}, ${item.hearingOfficerFName}`;
          options.push(obj);
        });
    }

    return (
      <SelectSearch
        options={options}
        search={true}
        value={selectedneutralName}
        name="hearingofficers"
        id="hearingofficers"
        emptyMessage="No record found"
        onChange={this.selecteharingOfficer}
        getOptions={this.getOptions}
        placeholder={
          selectedneutralName !== undefined &&
          selectedneutralName !== null &&
          selectedneutralName !== ""
            ? selectedneutralName
            : selectAll
        }
      />
    );
  };

  getOptions=async (searchValue)=>{
    return searchValue?
    JSON.parse(JSON.stringify(options)).filter(element=>element.name.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1)
    :options;
  }

  selecteharingOfficer = async (neutralEmail) => {
    
    if (neutralEmail === selectAll) {
      ////console.log("Before", this.state.hearingOfficers);
      this.setState({
        selectedneutralName: selectAll,
      });
      this.GetNeutralCalendarINfo();
    ////  console.log("After", this.state.hearingOfficers);
    } else {
      const result = this.state.hearingOfficers.filter((obj) => {
        ////console.log(obj, neutralEmail);
        return obj.hearingOfficerCode === neutralEmail;
      });
      this.setState({
        selectedneutralName: `${result[0].hearingOfficerLName}, ${result[0].hearingOfficerFName}`,
        selectedneutralEmail: `${result[0].hearingOfficerEmail}`,
        selectedneutralId: result[0].hearingOfficerCode,
      });
      this.GetCalendarData(
        result[0].hearingOfficerEmail,
        this.state.startDate,
        this.state.endDate,
        false,
        result[0].hearingOfficerCode
      );
    }
  };
  render() {
    const { events, hearingOfficers, isclaendarLoaded } = this.state;
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        text="Loading your content..."
      >
        <div
          className="calendersec"
          style={{ marginTop: "4rem", padding: "0% 3%" }}
        >
          <div>
            <div className="leftContainer"></div>
          </div>
          <div className="topBar">
            {hearingOfficers.length > 0 && (
              <div className="disFlexDiv">
                <p className="hearingOfficer">Hearing Officer</p>
                {this.hearingOfficersrender()} 
              </div>
            )} 
              
            <div className="topBarRightDiv">
              <Button onClick={() => { this.props.history.push("/neutralvacation");}}>+Add Vacation</Button>
            </div>
          </div>
          {/* <div className="rightContainer" style={{ width: "100%" }}></div> */}
          {isclaendarLoaded && (
            <>
            <br></br>
            <div className="disFlexDiv"><p style={{textAlign:"left"}}>All times in {Utility.userSpecificTimeZone()}</p>
            </div>
            <Calendar
              events={events}
              startAccessor="start"
              endAccessor="end"
              views={["month", "week", "day"]}
              onView={this.handleView}
              defaultDate={moment().toDate()}
              localizer={localizer}
              tooltipAccessor={null}
              onSelectEvent={this.handleSelectEvent}
              scrollToTime={new Date()}
              components={{
                event: CustomEventContainer({
                  view: this.state.view,
                }),
                toolbar: CustomRBCToolbarContainer({
                  stateObj: this.state,
                }),
              }}
              min={new Date(0, 0, 0, 0, 0, 0)}
              max={new Date(0, 0, 0, 23, 59, 59)}
              onNavigate={this.onNavigate}
              dayLayoutAlgorithm="no-overlap"
            />
            </>
          )}
        </div>
      </LoadingOverlay>
    );
  }
}

const CustomEventContainer =
  ({ view }) =>
  (props) => {
    return <Event event={props} view={view} />;
  };
const CustomRBCToolbarContainer =
  ({ stateObj }) =>
  (props) => {
    return (
      <RBCToolbar
        props={props}
        stateObj={stateObj}
        selectChange={selectChange}
      />
    );
  };
const selectChange = (value, props) => {
  console.log(value, props);
};
export default CaseCalendar;
