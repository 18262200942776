import React from "react";

const fieldSetLabelHeader = () => {
  return (
    <div className="fieldsetHeaderContainer">
      <div className="fieldsetHeaderLeft">Neutral Vacation</div>
    </div>
  );
};

export const NeutralVacationModel = () => {
  return [
    {
      fieldSetClassName: "custom-fieldset",
      fieldSetLabelType: "string",
      fieldSetLabel: "",
      columns: [
        {
          colmd: "12",
          colxs: "6",
          childType: "fieldset",
          fieldsets: [
            {
              fieldSetClassName: "neutral-vacation-fieldset",
              fieldSetLabelType: "component",
              fielsetId: "neutralVacationFieldset",
              fieldSetLabel: fieldSetLabelHeader,
              rows: [
                [
                  {
                    colmd: "12",
                    key: "lblSeperator1",
                    type: "label",
                    value: "",
                    className: "custom-label-sep-line",
                  },
                ],
                [
                  {
                    colmd: "4",
                    id: "startDate",
                    key: "startDate",
                    label: "Start Date",
                    type: "date",
                    min: new Date().toISOString().split("T")[0],
                    validators: [
                      {
                        name: "required",
                        value: true,
                        validationMessage: "Please select the Start Date",
                      },
                    ],
                    noDate: null,
                  },
                  {
                    colmd: "4",
                    id: "endDate",
                    key: "endDate",
                    label: "End Date",
                    type: "date",
                    min: new Date().toISOString().split("T")[0],
                    validators: [
                      {
                        name: "required",
                        value: true,
                        validationMessage: "Please select the End Date",
                      },
                    ],
                    noDate: null,
                  },
                ],
                [
                  {
                    colmd: "12",
                    id: "note",
                    key: "note",
                    label: "Note (optional)",
                    type: "textarea",
                    rows: "5",
                    validators: [
                      {
                        name: "maxLength",
                        value: 500,
                        validationMessage: "Max 500 characters are allowed",
                      },
                    ],
                  },
                ],
              ],
            },
          ],
        },
        {
          colmd: "12",
          colxs: "6",
          rows: [
            [
              {
                colmd: "2",
                class: "neutralVacationSpaceButtons",
              },
              {
                colmd: "4",
                key: "goBackButton",
                id: "goBackButton",
                type: "button",
                value: "Go Back",
                class: "btn neutralVacationButtons",
              },
              {
                colmd: "4",
                key: "addVacationButton",
                id: "addVacationButton",
                type: "button",
                value: "Add Vacation",
                class: "btn neutralVacationButtons",
              },
              {
                colmd: "2",
                class: "neutralVacationSpaceButtons",
              },
            ],
          ],
        },
      ],
    },
  ];
};
