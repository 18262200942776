import { combineReducers } from 'redux';
import userreducer from './userreducer';
import casereducer from './casereducer';
import neutralvacationreducer from './neutralvacationreducer';

export default combineReducers({
    userreducer,
    casereducer,
    neutralvacationreducer
});
