import * as types from "../constants/userConstant";
const initialState = {
  neutralvacationresponse: {},
};

function neutralvacationreducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_NEUTRAL_VACATION:
      return {
        ...state,
        neutralVacationsState: action.response,
      };
    case types.SAVE_NEUTRAL_VACATION:
      return {
        ...state,
        saveNeutralVacationState: action.response,
      };
    case types.DELETE_NEUTRAL_VACATION:
      return {
        ...state,
        deleteNeutralVacationState: action.response,
      };
    default:
      return state;
  }
}

export default neutralvacationreducer;
