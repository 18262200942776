import React from "react";
import ModalPopup from "f1-modal-react";
import { Button } from "@mui/material";
import "./CustomModalPopup.css";

const CustomModalPopup = ({
  show,
  header,
  alertText,
  onButtonClick,
  eventType,
  onHidePopup,
  parentClassName = "",
  showOkButtonOnly = false,
  showCloseButton = true,
}) => {
  let parentClass = "neutral-vacation-model";
  if (parentClassName && parentClassName !== "") {
    parentClass = parentClass + " " + parentClassName;
  }

  return (
    <div className="custom-modal-popup">
      <ModalPopup
        className="customModal"
        parentClassName={`${parentClass} alert-model-box`}
        show={show}
        size={"lg"}
        centered={true}
        headerClassName="alert-custom-modal-header"
        modelHeader={<h1 className="alert-custom-modal-header">{header}</h1>}
        modelContent={<p className="alert-pop-title">{alertText}</p>}
        footerClassName="alert-pop-custom-modal-footer"
        modelFooter={
          showOkButtonOnly ? (
            <div>
              <Button
                className="btn modal-btn"
                name="ok"
                onClick={(event) => {
                  onButtonClick(event, eventType);
                }}
              >
                Ok
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                className="btn page-btn"
                name="no"
                onClick={(event) => {
                  onButtonClick(event, eventType);
                }}
              >
                No
              </Button>
              &nbsp;
              <Button
                className="btn modal-btn"
                name="yes"
                onClick={(event) => {
                  onButtonClick(event, eventType);
                }}
              >
                Yes
              </Button>
            </div>
          )
        }
        closeButtonShow={showCloseButton}
        backdrop={"static"}
        scrollable={false}
        onHide={() => {
          onHidePopup({
            show: false,
          });
        }}
      />
    </div>
  );
};

export default CustomModalPopup;
